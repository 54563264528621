<template>
  <sdModal
    centered
    type="primary"
    :title="i18n.t('accounts.invest.closing')"
    :visible="data.visible"
    :onCancel="closeModal"
    :width="500"
    class="publish-modal"
  >
    <a-alert :message="error" type="warning" v-if="error" show-icon style="margin-bottom: 20px" />
    <a-form name="close" ref="close">
      <a-form-item
        ref="closeAccount"
        name="closeAccount"
        class="input__wrapper"
        :label="i18n.t('accounts.invest.closing_account')"
      >
        <a-input
          :value="`${data.data?.name} (${data.data?.amount} ${data.data?.amount_currency})`"
          size="large"
          type="text"
          :disabled="true"
          style="width: 100%"
        />
      </a-form-item>
      <p>*{{ i18n.t('accounts.invest.after_close') }}</p>
      <div class="modal-buttons df-jcsb-aic">
        <sdButton class="action__button" size="large" :disabled="isLoading" type="danger" @click="closeModal" raised>
          {{ i18n.t('start.cancel') }}
        </sdButton>
        <sdButton class="action__button" size="large" :disabled="isLoading" type="primary" @click="modalAction" raised>
          {{ i18n.t('accounts.invest.close') }}
        </sdButton>
      </div>
    </a-form>
  </sdModal>
</template>

<script>
import { computed, reactive } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';

export default {
  props: {
    data: {
      type: Object,
      default() {
        return {
          visible: false,
          data: null,
          type: null,
        };
      },
    },
  },

  setup(props, { emit }) {
    const { state, dispatch } = useStore();
    const i18n = reactive(useI18n());

    const isLoading = computed(() => state.publicAccounts.loading);

    const error = computed(() => {
      const err = state.publicAccounts.error;
      if (err) {
        if (typeof err === 'object') {
          return Object.entries(err).map((value) => value[1][0]);
        } else {
          return err == 500 ? i18n.t('error.500') : err;
        }
      }
      return false;
    });

    const closeModal = () => {
      emit('update:data', { visible: false, data: null });
    };

    const modalAction = async () => {
      await dispatch('closeInvestorAccount', { id: props.data.data.id });
      if (!error.value) {
        closeModal();
      }
      return;
    };

    return {
      i18n,
      isLoading,
      modalAction,
      closeModal,
      error,
    };
  },
};
</script>

<style lang="scss" scoped>
.modal-buttons {
  margin-top: 20px;
  gap: 10px;
  flex-wrap: nowrap;
  button {
    width: 50%;
  }
}
p {
  font-size: 14px;
  opacity: 0.5;
}
</style>
